import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { styled, Theme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Layout from '../layout';
import FloatingButton from 'src/components/FloatingButton';
import { HtmlToText } from 'src/utils';
import SEO from 'src/components/SEO';
import JobSEO from 'src/components/JobSEO';

interface Props {
  data: {
    lever: {
      text: string;
      lever_id: string;
      lists: {
        content: string;
        text: string;
      }[];
      applyUrl: string;
      additional: string;
      description: string;
      categories: {
        commitment: string;
        department: string;
        location: string;
        team: string;
      };
    };
  };
}

const HeaderSection = styled('section')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  backgroundColor: '#fff',
  justifyContent: 'space-between',
  flexDirection: 'column',
  paddingBottom: 20,

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const Team = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 700,

  [theme.breakpoints.up('md')]: {
    fontSize: 48,
  },
})) satisfies typeof Typography;

const JobTitle = styled(Typography)(({ theme }) => ({
  color: '#00dd99',
  fontSize: 20,
  marginTop: 12,

  [theme.breakpoints.up('md')]: {
    fontSize: 24,
    marginTop: 0,
  },
})) satisfies typeof Typography;

const ContentWrapper = styled('div')(({ theme }) => ({
  paddingTop: 75,
  fontSize: '1.25rem',
  wordBreak: 'keep-all',

  '& .postings-link': {
    color: '#18DA9E',
    textDecoration: 'none',
  },

  '& .postings-link:visited': {
    color: '#AAAAAA',
    textDecoration: 'none',
  },

  '& b': {
    fontFamily: ['Poppins', 'Pretendard', 'Noto Sans KR', 'system-ui', 'sans-serif'].join(','),
  },

  [theme.breakpoints.down('md')]: {
    paddingTop: 40,
    fontSize: '16px',
  },
}));

const Content = styled('div')(({ theme }) => ({
  marginBottom: 30,
  '& > b': {
    fontWeight: 'bold',
    display: 'block',
    margin: '12px 0',
    marginTop:50,
    fontSize: '26px',
  },
}));

const Additional = styled('div')({
  marginBottom: 30,
  '& b': {
    fontSize: 16,
    display: 'block',
    marginTop: '15px',
  },
});

const FooterSection = styled('section')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: 480,
    backgroundImage: 'url("/backgrounds/img-bg-type-03.svg")',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '-170px',
  },
}));

const SectionHeading = styled(Typography)(({ theme }) => ({
  lineHeight: 1.5,
  fontSize: 24,
  marginBottom: 16,

  [theme.breakpoints.up('md')]: {
    lineHeight: 1,
    fontSize: 72,
    marginBottom: 24,
  },
})) satisfies typeof Typography;

const JobTemplate: FC<Props> = ({ data }) => {
  const { lever } = data;

  return (
    <Layout>
      <FloatingButton
        label="지원하기"
        alignRight
        width={120}
        onClick={() => (window.location.href = lever.applyUrl)}
      />
      <Container>
        <HeaderSection>
          <Team variant="h2">{lever.text}</Team>
          <JobTitle variant="h1">{lever.categories.team}</JobTitle>
        </HeaderSection>
        <Grid container>
          <Grid xs={12} md={9}>
            <ContentWrapper>
              <div dangerouslySetInnerHTML={{ __html: lever.description }} />
              {lever.lists.map(item => (
                <Content>
                  <Typography component="b">{item.text}</Typography>
                  <Content dangerouslySetInnerHTML={{ __html: item.content }} />
                </Content>
              ))}
              <Additional
                dangerouslySetInnerHTML={{ __html: lever.additional }}
              />
            </ContentWrapper>
          </Grid>
        </Grid>
      </Container>
      <FooterSection>
        <Container>
          <SectionHeading variant="h1"></SectionHeading>
        </Container>
      </FooterSection>
    </Layout>
  );
};

export default JobTemplate;

export const Head = ({ data }) => {
  const { lever } = data;

  return (    
    <JobSEO lever={lever} />
  )
}

export const postQuery = graphql`
  query($lever_id: String) {
    lever(lever_id: { eq: $lever_id }) {
      additional
      applyUrl
      categories {
        commitment
        department
        location
        team
      }
      description
      lever_id
      lists {
        content
        text
      }
      text
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
