import React, { FC } from 'react';
import SEO from '../SEO';
import { HtmlToText } from 'src/utils';

interface Props {
  lever: {
    text: string;
    lever_id: string;
    lists: {
      content: string;
      text: string;
    }[];
    applyUrl: string;
    additional: string;
    description: string;
    categories: {
      commitment: string;
      department: string;
      location: string;
      team: string;
    };
  };
}

const JobSEO: FC<Props> = ({ lever }) => {
  const description = HtmlToText(
    lever.description +
      lever.lists.map(item => '\\n' + item.text + '\\n' + item.content)
  );
  return (
    <SEO title={lever.text} description={'지원하기'}>
      <script type="application/ld+json">
        {`
          {
            "@context":"http://schema.org",
            "@type":"JobPosting",
            "title":"[${lever.categories.team}] ${lever.text}",
            "description":"${HtmlToText(lever.additional)}",
            "url":"${lever.applyUrl}",
            "hiringOrganization":{
              "@type":"Organization",
              "name":"하이퍼커넥트",
              "logo":"https://static.wanted.co.kr/images/wdes/0_4.8e46863d.jpg"
            },
            "datePosted":"2021-02-1",
            "industry":"${lever.categories.team}",
            "occupationalCategory":"[${lever.categories.team}] ${lever.text}"
            "employmentType":"FULL_TIME",
            "jobLocation":{
              "@type":"Place",
              "address":{
                "@type":"PostalAddress",
                "addressRegion":"서울",
                "addressCountry":"KR",
                "streetAddress":"강남구 영동대로 517 아셈타워"
              }
            },
            "experienceRequirements":"${description}"
          }`}
      </script>
    </SEO>
  );
};

export default JobSEO;
