import React, { FC, PropsWithChildren } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

interface Props {
  title?: string;
  description?: string;
  image?: string;
  article?: boolean;
}

const SEO: FC<PropsWithChildren<Props>> = ({ title, description, image, article, children }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl: url
          defaultImage: image
          twitterUsername
        }
      }
    }
  `);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <>
      <title>
        {titleTemplate.replace('%s', seo.title)}
      </title>
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && 
        <>
          <meta name="twitter:title" content={seo.title} />
          <meta property="og:title" content={seo.title} />
        </>}
      {seo.description && (
        <>
        <meta name="description" content={seo.description} />
        <meta property="og:description" content={seo.description} />
        <meta name="twitter:description" content={seo.description} />
        </>
      )}
      {seo.image && (
        <>
        <meta name="image" content={seo.image} />
        <meta property="og:image" content={seo.image} />
        <meta name="twitter:image" content={seo.image} />
        </>
      )}
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
        {children}
    </>
  );
};


export default SEO;
