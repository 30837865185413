export function getQueryVariable(search: string, variable: string): string {
  const query = search.substring(1);
  const vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return '';
}

export function HtmlToText(html: string = ''): string {
  const regExp = html
    .replace(/&nbsp;/g, '')
    .replace(/<\/div>/g, '\\n')
    .replace(/<\/li>/g, '\\n')
    .replace(/(<([^>]+)>)/g, '');
  return regExp;
}
