import React, { FC } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

interface StyleProps {
  width: number | string;
  alignRight: boolean;
}

interface Props extends StyleProps {
  onClick: () => void;
  label: string;
}

const Button = styled(Grid)<StyleProps>(({ theme, width, alignRight }) => ({
  position: 'fixed',
  backgroundColor: '#000',
  paddingLeft: 10,
  cursor: 'pointer',

  [theme.breakpoints.up('md')]: {
    right: 100,
    bottom: 100,
    width: 406,
    height: 84,
    borderRadius: 42,
  },
  [theme.breakpoints.down('md')]: {
    bottom: 30,
    right: alignRight ? 30 : '50%',
    transform: alignRight ? 'none' : 'translateX(50%)',
    width: width || 257,
    height: 51,
    borderRadius: 25,
  },
}));

const ButtonText = styled(Typography)(({ theme }) => ({
  color: '#ffffff',
  fontSize: 23,
  [theme.breakpoints.down('md')]: {
    fontSize: 16,
  },
})) as typeof Typography;

const ButtonDot = styled(Grid)(({ theme }) => ({
  backgroundColor: '#00dd99',
  width: 16,
  height: 16,
  marginTop: '-20px',
  marginLeft: 10,
  borderRadius: '50%',

  [theme.breakpoints.down('md')]: {
    marginLeft: 5,
    width: 8,
    height: 8,
  },
}));

const FloatingButton: FC<Props> = ({ onClick, label, ...styleProps }) => (
  <Button
    container
    alignItems="center"
    justifyContent="center"
    onClick={onClick}
    {...styleProps}
  >
    <Grid item>
      <ButtonText component="p">{label}</ButtonText>
    </Grid>
    <ButtonDot item />
  </Button>
);

export default FloatingButton;
